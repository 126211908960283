const config = {
  APP_NAME: "JESUS VISION",
  AUTH: {
    DRIVER: localStorage,
    REDIRECT_LOGIN: "/",
    REDIRECT_LOGOUT: "/login",
    API_HOST: "https://api.jesusvision.in/",
    //API_HOST: 'http://localhost:3000/'
  },
};

export default config;
