import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialLinkedin,
  SlSocialTwitter,
  SlSocialYoutube,
} from "react-icons/sl";
import { Link } from "react-router-dom";

import { Container } from "../components/utils";

const Footer = () => {
  return (
    <footer className="bg-[#FBFBFF] py-24 mt-10 border-t border-indigo-100">
      <Container>
        <div
          className="flex  justify-between items-start flex-col  lg:flex-row gap-1 w-full
        ">
          <div className="w-1/2  lg:w-1/4 text-center">
            <img src="../assets/img/logo-b.png" className="" alt="" />
          </div>
          <div className="">
            <h6 className=" text-black font-bold"> Registered address</h6>
            <p>
              Jesus Vision Deliverance Ministry, <br /> Ealayil House,
              <br />
              Kanjiramala,
              <br /> Paper Mill PO, <br />
              Punalur, Kollam, <br />
              Kerala, India <br />
              691332
            </p>
          </div>

          <div className="">
            <h6 className=" text-black font-bold"> Office address</h6>
            <p>
              Jesus Vision Deliverance Ministry, <br /> Pappachan Kunjumol
              Foundation,
              <br /> Kizhakkeparanthu Velil, Mampilali, <br />
              Thumpamon PO,
              <br /> Pandalam Pathanamthitta,
              <br /> Kerala, India <br /> 689502
            </p>
          </div>
          <div className="">
            <h6 className=" text-black font-bold"> Contact</h6>
            <p>
              prayer@jesusvision.in
              <br />
              +91 944 768 7078
            </p>
          </div>
        </div>

        <div className="mt-8 flex flex-col lg:flex-row gap-4 items-center justify-between">
          <p className="text-gray-500 uppercase text-sm md:text-base">
            Copyright © 2022 JesusVision. All Rights Reserved.
          </p>

          <div className="flex items-center space-x-4 text-indigo-800">
            <a
              href="https://www.youtube.com/c/JesusVisionDM/featured"
              target="_blank"
              className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
              <SlSocialYoutube className="w-6 h-6" />
            </a>

            <a
              href="https://www.facebook.com/JesusVisionDeliveranceMinistry"
              target="_blank"
              className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
              <SlSocialFacebook className="w-6 h-6" />
            </a>

            <a
              href="https://twitter.com/vision_jesus"
              target="_blank"
              className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
              <SlSocialTwitter className="w-6 h-6" />
            </a>

            <a
              href="https://www.instagram.com/jesusvisiondeliveranceministry/"
              target="_blank"
              className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
              <SlSocialInstagram className="w-6 h-6" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCku70h6BX7cZi9JZuAjDrZg/videos"
              target="_blank"
              className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2">
              <SlSocialYoutube className="w-6 h-6" />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
