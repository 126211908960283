import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Container } from "../components/utils";
const CampServices = ({ meetings }) => {
  console.log(meetings);
  return (
    <section className="w-full overflow-hidden bg-white wow fadeInUp mt-[5rem]">
      <Container>
        <div className="grid gap-6 lg:grid-cols-4 lg:grid-rows-2">
          <div className="col-span-2">
            <h1 className="text-3xl lg:text-5xl text-black leading-snug font-semibold">
              We provide the Services that helps you to connect with GOD.
            </h1>
            <p className="text-base text-gold-1 mt-4">
              Please follow the below details to get know more about our camps
              and details
            </p>
          </div>
          {meetings.map((camp, i) => {
            return (
              <Link to="/camps" key={i}>
                {camp.statusId === 1 && (
                  <div
                    key={i}
                    className="bg-gold-10 max-h-72 p-5 rounded-xl overflow-hidden flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                    <h3 className="text-xl text-black font-medium">
                      {camp.title}
                    </h3>
                    <p className="text-sm text-gold-1 whitespace-pre-wrap max-h-20 overflow-hidden">
                      {camp.description}
                    </p>
                  </div>
                )}
              </Link>
            );
          })}
        </div>
      </Container>
    </section>
  );
};
export default CampServices;
