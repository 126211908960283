import React, { useEffect, useState } from "react";
import { useNavigate , useParams} from "react-router-dom";
import { PrimaryButton } from "../components/buttons";
import { Checkbox, Input, RadioButton } from "../components/field";
import { Link, Loader, toastSwal } from "../components/utils";
import { toast } from "../helpers";
import config from "../config";
 
import AuthLayout from "../layouts/AuthLayout";
import { directBookingService } from "../services/Authentication";
import { getCampByID } from "../services/Meetings";

const DirectBooking = () => {
  const params = useParams();
  const navigate = useNavigate();
  const defaultMessage = {
    email: [],
    password: [],
    phone: [],
  };
  const plans = ["startup", "business", "enterprise"];
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [fullname, setFullname] = useState("");
  const [age, setAge] = useState("");
  const [place, setPlace] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultMessage);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [campInfo, setCampInfo] = useState({});


  useEffect(() => {
    console.log(params)
    getCampById(params.id)
  }, []);

  const getCampById = (id) => {
    getCampByID(id)
      .then((res) => {
        console.log(res)
        if(res.code === 200){
          setCampInfo(res.data)
        }
      })
      .catch((err) => {});
  };
  const register = () => {
    const newErrorMessage = defaultMessage;
    if (!email) {
      newErrorMessage.email = ["This field is required"];
    }
    if (!fullname) {
      newErrorMessage.fullname = ["This field is required"];
    }
    if (!age) {
      newErrorMessage.age = ["This field is required"];
    }
    if (!gender) {
      newErrorMessage.gender = ["This field is required"];
    }
    if (!place) {
      newErrorMessage.place = ["This field is required"];
    }
    if (!phone) {
      newErrorMessage.phone = ["This field is required"];
    }
    setInvalid(false);
 


    if (fullname && email && phone && age && gender && place) {
      const fields = {
        email: email,
        age: age,
        phone: phone,
        gender: gender,
        fullName: fullname,
        place: place,
        meetingId:+params.id,
      };
      let title = "";
      let success = "";
      setLoading(true);
      directBookingService(fields).then((res) => {
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Registration successfull";
          navigate('/');
        } else {
          title = "error";
          success = res.message;
        }
        toastSwal(title,success,'Ok')
        setLoading(false);
      });
    } else {
      setErrorMessage(defaultMessage);
    }
  };

  return (
    <AuthLayout
      title={
        <>
          {campInfo?.title}
        </>
      }
    >
      <h3 className="text-center text-xl font-semibold text-gray-700">
      {campInfo?.title}
      </h3>
      <p className="text-center text-sm mt-2">
      {campInfo?.description}
      </p>
      <p className="text-center text-sm mt-2 mb-10">
      {campInfo?.locations?.name}
      </p>
      <form className="space-y-5">
        <div>
          <Input
            label={"Name"}
            id="fullname"
            type="text"
            placeholder="Enter your full name"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            error={errorMessage.fullname}
          />
        </div>
        <div>
          <Input
            label={"Phone number"}
            id="phone_number"
            type="tel"
            placeholder="Enter phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={errorMessage.phone}
          />
        </div>

        <div>
          <Input
            label={"Email"}
            id="email"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage.email}
          />
        </div>
        <div>
          <RadioButton
            id="male"
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            label="Male"
            checked={gender === "male" ? true : false}
          />
          <RadioButton
            id="female"
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            label="Female"
            checked={gender === "female" ? true : false}
            error={errorMessage.gender}
          />
        </div>

        <div>
          <Input
            label={"Place"}
            id="place"
            type="text"
            placeholder="Enter your place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
            error={errorMessage.place}
          />
        </div>

        <div>
          <Input
            label={"Age"}
            id="age"
            type="number"
            placeholder="Enter your age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            error={errorMessage.place}
          />
        </div>

        {/* <div>
          <span className="text-sm cursor-pointer">
            By Submit, I agree to the privacy policy & terms and conditions
          </span>
        </div> */}

        <p className="text-sm text-center">
          Please make sure the phone number is valid <br />
          JVDM office will call you to confirm the Camp Registration
        </p>

        <PrimaryButton onClick={register}>
          {loading && <Loader color={"white"} />}
          <span>Register</span>
        </PrimaryButton>
      </form>
    </AuthLayout>
  );
};

export default DirectBooking;
