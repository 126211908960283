import { HiBeaker } from "react-icons/hi2";
import { Link } from "react-router-dom";

const AuthLayout = ({ title = "", children }) => {
  return (
    <div className="flex text-gray-800 p-4 md:py-6 md:px-32 lg:p-0">
      <div className="flex flex-col-reverse lg:flex-row w-screen lg:min-h-screen border shadow-sm rounded-lg overflow-hidden lg:border-none lg:shadow-none lg:rounded-none lg:overflow-auto">
        <div className="flex flex-col justify-between text-white lg:min-h-screen w-full lg:w-7/12 xl:w-3/5 bg-[#111827]">
          <img className="w-8/12 h-auto" src="/images/auth-5.png" alt="" />

          <div className="space-y-8 p-9">
            <Link to="/" className="flex justify-start items-center space-x-3">
              <img
                src="../assets/img/logo.png"
                className="py-1"
                width={45}></img>
              <div>
                <p className="inline text-xl md:text-2xl uppercase font-bold tracking-wide leading-[0.9rem]">
                  JESUS<span className="font-[500]"> VISION</span>
                </p>
                <div className="flex items-center space-x-1 leading-[0.5rem]">
                  <span className="text-[0.83rem] font-light tracking-wider   text-white uppercase leading-[0.6rem]">
                    DELIVERANCE MINISTRY
                  </span>
                </div>
              </div>
            </Link>

            <div className="space-y-4">
              <h1 className="text-2xl lg:text-4xl font-semibold">{title}</h1>

              <p className="font-medium">
                so in Christ we, though many, and each member belongs to all the
                others. <br className="hidden lg:inline-block xl:hidden" /> form
                one body,
                <br className="hidden xl:inline-block" />{" "}
                <br className="hidden lg:inline-block xl:hidden" /> Romans 12:5
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center -space-x-3">
                <img
                  className="h-7 w-8 md:h-10 md:w-10 bg-gray-800 border border-white rounded-full object-cover object-center"
                  alt=""
                />
              </div>

              <p className="font-medium text-sm">
                Please follow our channels for updates and news
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center lg:min-h-screen p-6 md:p-10 lg:p-8 xl:p-10 w-full lg:w-5/12 xl:w-2/5">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
