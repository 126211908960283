import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { RiFacebookCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../components/buttons";
import { Checkbox, Input } from "../components/field";
import { Link, Loader } from "../components/utils";
import config from "../config";
import { toast } from "../helpers";
import AuthLayout from "../layouts/AuthLayout";
import { forgotPasswordService } from "../services/Authentication";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const defaultMessage = {
    email: [],
    password: [],
  };

  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultMessage);
  const [email, setEmail] = useState("");
  //   toast("error", "Connection failed");
  //   setErrorMessage(newErrorMessage);
  const login = () => {
    const newErrorMessage = defaultMessage;
    setInvalid(false);
    if (!email) {
      setLoading(false);
      if (!email) {
        newErrorMessage.email = ["This field is required"];
      }
 
      setInvalid(true);
    } else {
      setLoading(true);
      setInvalid(false);

      if (email) {
        const fields = {
          email: email
        };
        let title = "";
        let success = "";
        forgotPasswordService(fields).then((res) => {
          if (!res.isError) {
            setLoading(false);
            title = "success";
            success = "Email Otp send successfull";
            config.AUTH.DRIVER.setItem("token", '');
            config.AUTH.DRIVER.setItem("token", res.data.token);
            config.AUTH.DRIVER.setItem("email", fields.email);
            navigate("/verification");
          } else {
            title = "error";
            success = res.message;
          }
          toast(title, success);
          setLoading(false);
        });
      }
    }
  };

  return (
    <AuthLayout
      title={
        <>
          Welcome back to <br /> our community
        </>
      }>
      <h3 className="text-center text-xl font-semibold text-gray-700">
       Forgot password
      </h3>
      <p className="text-center text-sm mt-2 mb-10">
        Please enter your email address 
      </p>

      {invalid && (
        <div className="my-2 text-center text-red-600 bg-red-100 py-2 rounded-md">
          Invalid email  
        </div>
      )}

      <form className="space-y-5">
        <div>
          <Input
            label={"Email"}
            id="email"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage.email}
          />
        </div>  

        <PrimaryButton onClick={login} disabled={loading}>
          {loading && <Loader color={"white"} />}
          <span>Send Verification</span>
        </PrimaryButton>

        <div className="flex items-center justify-center space-x-3">
          <hr className="w-12" />
          <span className="font-bold uppercase text-xs text-gray-400">Or</span>
          <hr className="w-12" />
        </div>
      
        <p className="text-sm text-center">
          Don't have an account? <Link href="/register">Register</Link>
        </p>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
