import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { Container, Loader } from "../components/utils";
import { toast } from "../helpers/index";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { postContactUs } from "../services/Meetings";
const ContactUs = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneValue] = useState();
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setPhoneError(false);
    if (phone && isValidPhoneNumber(phone)) {
      const fields = {
        name: data.fullname,
        email: data.email,
        phone: phone,
        message: data.message,
        place: data.place,
        subject: data.enqFor,
      };

      postContactUs(fields).then((res) => {
        let title = "";
        let success = "";
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Thank you for your message. We will contact you soon";
          setPhoneValue("");
          document.getElementById("formcontact").reset();
        } else {
          title = "error";
          success = "Something went wrong. Please try again";
        }
        toast(title, success);
        setLoading(false);
      });
    } else {
      setPhoneError(true);
      setLoading(false);
    }
  };
  return (
    <section
      id="contact"
      className="w-full  bg-white wow fadeInUp my-[5rem]  overflow-visible">
      <Container className=" lg:mb-40 xl:mb-52">
        <div className="grid gap-10 lg:grid-cols-2  ">
          <div className="flex flex-col justify-start">
            <div>
              <h1 className="text-3xl lg:text-5xl text-black leading-[3rem] lg:leading-[4rem] font-semibold">
                Contact Us
              </h1>
              <p className="py-4 text-base text-gold-1 leading-7">
                Please send us your message and we will contact you soon in case
                of any emergency.
              </p>
            </div>

            <div className=" flex gap-5 flex-row py-3">
              <div className="flex flex-col  w-full bg-gold-10 gap-2 p-5 justify-center rounded-2xl">
                <div className="flex items-center gap-3">
                  <svg
                    width="23"
                    height="21"
                    viewBox="0 0 23 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22.06 14.418c0 3.108-2.471 5.626-5.548 5.637H5.57C2.504 20.055 0 17.55 0 14.441v-.012S.007 9.5.015 7.02a.55.55 0 0 1 .892-.437c2.62 2.1 7.307 5.93 7.365 5.98a4.459 4.459 0 0 0 2.79.99 4.4 4.4 0 0 0 2.791-1.003c.059-.039 4.64-3.753 7.301-5.887a.55.55 0 0 1 .895.434c.01 2.46.01 7.322.01 7.322z"
                      fill="#B68A35"
                    />
                    <path
                      d="M21.481 2.979A5.546 5.546 0 0 0 16.578 0H5.57A5.546 5.546 0 0 0 .666 2.979a.968.968 0 0 0 .244 1.2l8.19 6.617c.573.468 1.268.7 1.963.7h.022c.695 0 1.39-.232 1.963-.7l8.19-6.616a.968.968 0 0 0 .243-1.201z"
                      fill="#000"
                    />
                  </svg>
                  <p className="text-base text-gold-1">Email Us</p>
                </div>
                <h3 className="text-xl text-black leading-snug font-base ">
                  Prayer@jesusvison.in
                </h3>
              </div>
              <div className="flex flex-col  w-full bg-gold-10 gap-2 p-5 justify-center rounded-2xl">
                <div className="flex items-center gap-3">
                  <svg
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.04 3.666a.897.897 0 0 0-1.054.715.906.906 0 0 0 .71 1.06 3.636 3.636 0 0 1 2.882 2.888v.001a.902.902 0 0 0 1.056.715.908.908 0 0 0 .712-1.062 5.434 5.434 0 0 0-4.306-4.317z"
                      fill="#000"
                    />
                    <path
                      d="M12.974.008a.858.858 0 0 0-.657.185.896.896 0 0 0-.339.614.903.903 0 0 0 .795.997c3.404.38 6.05 3.032 6.433 6.447a.9.9 0 1 0 1.79-.202C20.519 3.79 17.22.482 12.974.01z"
                      fill="#000"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.485 11.52c4.19 4.188 5.14-.657 7.807 2.008 2.571 2.57 4.05 3.086.791 6.343-.408.328-3 4.274-12.113-4.836C-3.144 5.924.8 3.328 1.128 2.92 4.394-.346 4.9 1.14 7.47 3.712c2.668 2.666-2.175 3.62 2.014 7.808z"
                      fill="#B68A35"
                    />
                  </svg>
                  <p className="text-base text-gold-1">Call Us</p>
                </div>
                <h3 className="text-xl text-black leading-snug" dir="ltr">
                  +91 944 768 7078
                </h3>
              </div>
            </div>
            <div className="flex items-center justify-start gap-5 my-4">
              <h4 className="text-black text-base leading-snug">
                Follow Us on
              </h4>

              <a
                href="https://www.facebook.com/JesusVisionDeliveranceMinistry"
                target="_blank"
                rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.548 20v-9.122h3.06l.46-3.556h-3.52v-2.27c0-1.03.285-1.731 1.762-1.731l1.882-.001V.14A25.512 25.512 0 0 0 12.45 0C9.735 0 7.877 1.657 7.877 4.7v2.622h-3.07v3.556h3.07V20h3.671z"
                    fill="#000"
                  />
                </svg>
              </a>

              <a
                href="https://twitter.com/vision_jesus"
                target="_blank"
                rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.799a8.549 8.549 0 0 1-2.363.647 4.077 4.077 0 0 0 1.804-2.266 8.192 8.192 0 0 1-2.6.992A4.099 4.099 0 0 0 9.75 5.976c0 .325.027.638.095.935-3.409-.166-6.425-1.8-8.451-4.288A4.128 4.128 0 0 0 .83 4.694c0 1.42.732 2.679 1.821 3.407A4.05 4.05 0 0 1 .8 7.597v.045a4.119 4.119 0 0 0 3.285 4.03 4.09 4.09 0 0 1-1.075.134c-.262 0-.527-.015-.776-.07.531 1.624 2.038 2.818 3.831 2.856a8.238 8.238 0 0 1-5.084 1.75A7.67 7.67 0 0 1 0 16.284a11.543 11.543 0 0 0 6.29 1.84c7.545 0 11.67-6.25 11.67-11.668 0-.18-.006-.356-.015-.53A8.179 8.179 0 0 0 20 3.8z"
                    fill="#000"
                  />
                </svg>
              </a>

              <a
                href="https://www.instagram.com/jesusvisiondeliveranceministry/"
                target="_blank"
                rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#sj9bu1pkia)" fill="#000">
                    <path d="M19.98 5.88c-.046-1.063-.218-1.793-.464-2.426a4.88 4.88 0 0 0-1.157-1.774 4.922 4.922 0 0 0-1.77-1.153C15.952.281 15.226.11 14.163.063 13.093.012 12.753 0 10.037 0 7.322 0 6.982.012 5.915.059 4.853.105 4.122.277 3.49.523A4.88 4.88 0 0 0 1.715 1.68 4.924 4.924 0 0 0 .563 3.45C.316 4.087.145 4.813.098 5.876c-.051 1.07-.063 1.41-.063 4.126 0 2.715.012 3.055.059 4.122.047 1.063.219 1.793.465 2.426a4.932 4.932 0 0 0 1.156 1.774c.5.508 1.106.902 1.77 1.152.637.247 1.364.419 2.426.465 1.067.047 1.407.059 4.122.059 2.716 0 3.056-.012 4.122-.059 1.063-.046 1.793-.218 2.426-.464a5.116 5.116 0 0 0 2.927-2.927c.246-.637.418-1.364.465-2.426.047-1.067.058-1.407.058-4.122s-.004-3.055-.05-4.122zm-1.8 8.166c-.044.977-.208 1.504-.345 1.856a3.317 3.317 0 0 1-1.898 1.898c-.352.137-.883.301-1.856.344-1.055.047-1.371.059-4.04.059-2.668 0-2.989-.012-4.04-.059-.977-.043-1.504-.207-1.856-.344a3.077 3.077 0 0 1-1.148-.746 3.11 3.11 0 0 1-.747-1.148c-.136-.352-.3-.883-.343-1.856-.047-1.055-.059-1.372-.059-4.04 0-2.669.012-2.99.059-4.04.043-.977.207-1.504.343-1.856A3.04 3.04 0 0 1 3 2.965a3.105 3.105 0 0 1 1.15-.746c.35-.137.882-.3 1.855-.344 1.055-.046 1.372-.058 4.04-.058 2.672 0 2.989.012 4.04.058.977.043 1.504.207 1.856.344.433.16.828.414 1.148.746.332.325.586.715.747 1.15.136.35.3.882.343 1.855.047 1.055.059 1.371.059 4.04 0 2.668-.012 2.98-.059 4.036z" />
                    <path d="M10.037 4.865A5.139 5.139 0 0 0 4.9 10.003a5.139 5.139 0 1 0 5.138-5.138zm0 8.47a3.333 3.333 0 1 1 .001-6.666 3.333 3.333 0 0 1 0 6.667zM16.578 4.66a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0z" />
                  </g>
                  <defs>
                    <clipPath id="sj9bu1pkia">
                      <path fill="#fff" d="M0 0h20v20H0z" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div className=" overflow-visible relative">
            <div className="p-10 shadow-2xl w-full  lg:absolute z-10 bg-white">
              <div className="">
                <div>
                  <h4 className="text-xl text-black font-medium py-3 leading-8">
                    Send Us
                  </h4>
                </div>
                <div className=" ">
                  <form id="formcontact" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="space-y-6 bg-white  py-5 ">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="fullname"
                              className="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              name="fullname"
                              key="fullname"
                              placeholder="Full Name"
                              aria-invalid={errors.fullname ? "true" : "false"}
                              {...register("fullname", {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                errors.fullname
                                  ? " border-red-600"
                                  : " border-gray-300"
                              }`}
                            />
                            {errors.fullname && (
                              <span
                                role="alert"
                                className=" text-red-500 text-xs">
                                Please enter your full name
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              aria-invalid={errors.email ? "true" : "false"}
                              {...register("email", {
                                required: false,
                              })}
                              autoComplete=" "
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                errors.email
                                  ? " border-red-600"
                                  : " border-gray-300"
                              }`}
                            />
                            {errors.email && (
                              <span
                                role="alert"
                                className="text-red-500 text-xs">
                                Please enter your Email
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="enqFor"
                              className="block text-sm font-medium text-gray-700">
                              Phone number
                            </label>

                            <PhoneInput
                              className={`${
                                phone
                                  ? isValidPhoneNumber(phone)
                                    ? " border-red-600"
                                    : " border-gray-300"
                                  : ""
                              }`}
                              international={false}
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              error={
                                phone
                                  ? isValidPhoneNumber(phone)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                              value={phone}
                              onChange={setPhoneValue}
                            />
                            {phoneError && (
                              <span
                                role="alert"
                                className="text-red-500 text-xs row-span-5">
                                Please enter your phone number
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700">
                              Place
                            </label>
                            <input
                              type="text"
                              name="place"
                              id="place"
                              {...register("place", {
                                required: true,
                              })}
                              placeholder="Location"
                              autoComplete=" "
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm `}
                            />
                          </div>
                          {errors.place && (
                            <span
                              role="alert"
                              className="col-span-5 text-red-500 text-xs">
                              Please enter your present location
                            </span>
                          )}
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="enqFor"
                            className="block text-sm font-medium text-gray-700">
                            Contact Request For
                          </label>
                          <select
                            id="enqFor"
                            name="enqFor"
                            placeholder="Choose a Topic"
                            autoComplete="enqFor"
                            {...register("enqFor", {
                              required: true,
                            })}
                            className={`mt-1 block w-full rounded-md border  border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}>
                            <option value="general">General</option>
                            <option value="prayerRequest">
                              Prayer Request
                            </option>
                            <option value="campEnquiry">Camp booking</option>
                            <option value="spiritualAdviceRequest">
                              Spiritual Advising
                            </option>
                          </select>
                        </div>

                        <div>
                          <label
                            htmlFor="message"
                            className="block text-sm font-medium text-gray-700">
                            Message / Request
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="message"
                              name="message"
                              {...register("message", {
                                required: true,
                              })}
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm"
                              placeholder="Please enter your message here"
                              defaultValue={""}
                            />
                          </div>
                          {errors.message && (
                            <span role="alert" className="text-red-500 text-xs">
                              Please enter your message
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="py-3">
                        <button
                          type="submit"
                          className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-gold-100 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2">
                          <span>Submit</span>
                          {loading && <Loader color={"white"} />}
                          <BsArrowRight size={20} />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ContactUs;
