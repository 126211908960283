import React, { useState} from "react";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {   Loader } from "../components/utils";
import { Checkbox } from "../components/field";
import { toast } from "../helpers/index";
import { patchFamilyMember } from "../services/usersService";
const EditMemberModal = ({ memberInfo,getFamilyMembers }) => {
  const [showModal, setShowModal] = useState(false);
  const [showChild, setShowChild] = useState(false);
  const [isChild, setIsChild] = useState(memberInfo.isChild); 
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({defaultValues:{
    name:memberInfo.name,
    age:memberInfo.age,
    gender:memberInfo.gender,
    place:memberInfo.place,
    relation:memberInfo.relation
  }});
  const [loading, setLoading] = useState(false);
 
  
  const onSubmit = async (data) => {
    setLoading(true);
    const fields = {
      age: data.age,
      id: memberInfo.id,
      gender: data.gender,
      name: data.name,
      place: data.place,
      relation: data.relation,
      isChild,
      userId: memberInfo.userId+''
    };

    patchFamilyMember(fields).then((res) => {
      let title = "";
      let success = "";
      if (!res.isError) {
        setLoading(false);
        setIsChild(true);
        setShowModal(false); 
        getFamilyMembers(memberInfo.userId);
        title = "success";
        success = "Member added";
        document.getElementById("formContact").reset();
      } else {
        title = "error";
        success = "Something went wrong. Please try again";
      }
      toast(title, success);
      setLoading(false);
      
    });
  };
  return (
    <> 
      <a
          href="#!"
          onClick={() => setShowModal(true)}
          className="border-gold-100 border text-sm rounded w-fix px-2  text-black"
        >
          Edit 
        </a>

      {showModal ? (
        <>
          <div className="flex bg-gray-800 bg-opacity-70 justify-center items-start overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-3/4 my-6 mx-auto ">
              <div className="p-10 shadow-2xl w-full  lg:absolute z-10 bg-white">
                <div className="">
                  <div>
                    <h4 className="text-xl text-black font-medium py-3 leading-8">
                      Edit  Member
                    </h4>
                  </div>
                  <div className=" ">
                    <form id="formContact" onSubmit={handleSubmit(onSubmit)}>
                      <div className="">
                        <div className="space-y-6 bg-white  py-5 ">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="Name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                key="name"
                                placeholder=" Name"
                                aria-invalid={errors.name ? "true" : "false"}
                                {...register("name", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.name
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.name && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your Name
                                </span>
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="age"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Age
                              </label>
                              <input
                                type="text"
                                name="age"
                                id="age"
                                placeholder="Age"
                                aria-invalid={errors.age ? "true" : "false"}
                                {...register("age", {
                                  required: false,
                                })}
                                autoComplete=" "
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                  errors.age
                                    ? " border-red-600"
                                    : " border-gray-300"
                                }`}
                              />
                              {errors.age && (
                                <span
                                  role="alert"
                                  className="text-red-500 text-xs"
                                >
                                  Please enter your Age
                                </span>
                              )}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Gender
                              </label>
                              <select
                                id="gender"
                                name="gender"
                                placeholder="Choose a Gender"
                                autoComplete="gender"

                                {...register("gender", {
                                  required: true,
                                })}
                                className={`mt-1 block w-full rounded-md border  border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}
                              >
                                <option value="male">MALE</option>
                                <option value="female">FEMALE</option>
                              </select>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="relation"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Relation
                              </label>
                              <select
                                id="relation"
                                name="relation"
                                placeholder="Choose a Relation"
                                autoComplete="relation"
                                {...register("relation", {
                                  required: true,
                                })}
                                onChange={e => setShowChild((e.target.value ==='son' || e.target.value === 'daughter') ? true : false)} 
                                className={`mt-1 block w-full rounded-md border  border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}
                              >
                                <option value="husband">HUSBAND</option>
                                <option value="wife">WIFE</option>
                                <option value="mother">MOTHER</option>
                                <option value="father">FATHER</option>
                                <option value="daughter">DAUGHTER</option>
                                <option value="son">SON</option>
                                <option value="sister">SISTER</option>
                                <option value="brother">BROTHER</option>
                                <option value="uncle">UNCLE</option>
                                <option value="aunty">AUNTY</option>
                              </select>
                            </div>
                            {showChild ? <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="relation"
                                className="block text-sm font-medium text-gray-700"
                              >
                              Is under age 12
                              </label>
                              <Checkbox  onChange={e => {setIsChild(e.target.value)}} 
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                            </div>: null }
                          </div>
                        </div>
                        <div className="py-3 flex gap-4">
                          <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-red-400 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <span>Cancel</span>
                            {loading && <Loader color={"white"} />}
                            <AiOutlineCloseCircle size={20} />
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-gold-100 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <span>Submit</span>
                            {loading && <Loader color={"white"} />}
                            <BsArrowRight size={20} />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default EditMemberModal;
