import { Container, Loader } from "../components/utils";
import Nav from "../layouts/Nav";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
import { getCamps } from "../services/Meetings";
import { useState } from "react";
import config from "../config";
import {Link} from 'react-router-dom';

const Camps = () => {
  const [camps, setCamps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const userInfo = JSON.parse(config.AUTH.DRIVER.getItem("user"));
    setUser(userInfo);
    getcamps(); 
  }, []);
  const getcamps = () => {
    setLoading(true);
    getCamps('[1,2,3,4,8]').then((res) => {
      setLoading(false);
      if (!res.isError) {
        setCamps(res.data);
      }
    });
  };
  return (
    <div>
      <Nav />

      <section className="w-full overflow-hidden  wow fadeInUp">
        <div className="relative bg-cover bg-no-repeat ">
          <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
            <Container className="pt-5">
              <div className="flex flex-col gap-10 justify-center">
                <div className="flex flex-col items-center justify-center ">
                  <h3 className="text-3xl lg:text-5xl text-black leading-snug font-semibold">
                    Upcoming Deliverance Camps
                  </h3>
                  <p className="text-base text-gold-1 mt-4 text-center lg:max-w-xl">
                    Please find the upcoming camps and you can book for the
                    camps once booking started
                  </p>
                  {loading && <Loader color={"black"} size={"2xl"} />}
                </div>
                <div className="items-center w-full">
                  <div className="grid gap-6 lg:grid-cols-3 lg:grid-rows-2">
                    {camps.map((camp) => {
                      return (
                         (
                          <div
                            key={camp.id}
                            style={{ backgroundColor: camp.bgColor? camp.bgColor : '' }}
                            className="bg-gold-10 max-h-72 p-5 rounded-xl overflow-hidden flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                            <h3 className="text-xl text-black font-medium">
                              {camp.title}
                            </h3>
                            <p className="text-md text-black whitespace-pre-wrap max-h-20 overflow-hidden">
                              {camp.description}
                            </p>
                            {user && user.id ? <a
                              href="/profile"
                              className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50">
                              Book Camp from Profile
                            </a> :( camp.statusId === 1  && !camp.hasDirectRegistration  ?    
                            <a
                              href="/login"
                              className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50">
                              Login to Book Camp
                            </a> :  
                            ( camp.statusId === 1  && camp.hasDirectRegistration  ? <>
                              <a
                              href="/login"
                              className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50">
                              Enrol and Book
                            </a> 
                            <Link  className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50" to={'/direct-booking/'+camp.id }>Quick Booking</Link>
                             </> : <a
                              href="/#"
                              className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50">
                                {camp.status.name}
                            </a>))}
                            <p className="text-sm text-gold-1 whitespace-pre-wrap max-h-20 overflow-hidden">
                              Please contact office for more details
                            </p>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Camps;
