import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { Container, Loader, toastSwal } from "../components/utils";
import { toast } from "../helpers/index";
import {
  getUserCamps,
  getFamilyCamps,
  postAttende,
} from "../services/Meetings";
const CampAttendess = ({ user, meetingId, memberss }) => {
  const [loading, setLoading] = useState(false);
  const [attendees, setAttendee] = useState([]);
  const [parent, setParent] = useState({});
  const [members, setMembers] = useState(memberss ? memberss : []);

  useEffect(() => {
    getUserCamp(user.id, meetingId);
  }, []);

  const bookCamp = (attende) => {
    let fields = {
      status: "pre-registered",
      meetingId,
    };
    if (attende.isParent) {
      fields.userId = attende.userId;
    } else {
      fields.parentId = user.id;
      fields.familyMemberId = attende.userId;
    }
    postAttende(fields).then((res) => {
      let title = "";
      let success = "";
      if (!res.isError) {
        setLoading(false);
        getUserCamp(user.id, meetingId);
        title = "success";
        success = "Registraion Successfull";
      } else {
        title = "error";
        success = "Something went wrong. Please try again";
      }
      toastSwal(title, success, 'Ok');
      setLoading(false);
    });
  };

  const getUserCamp = (userId, meetingId) => {
    setLoading(true);
    getUserCamps(userId, meetingId).then((res) => {
      if (!res.isError) {
        if (res.data && res.data.length > 0) {
          setParent({
            userId: user.id,
            name: user.fullName,
            isBooked: true,
            isParent: true,
            status: res.data[0].status,
          });
        } else {
          setParent({
            userId: user.id,
            name: user.fullName,
            isBooked: false,
            isParent: true,
          });
        }
        getFamilyCamp(userId, meetingId);
      }
      setLoading(false);
    });
  };

  const getFamilyCamp = (userId, meetingId) => {
    setLoading(true);

    getFamilyCamps(userId, meetingId).then((res) => {
      if (!res.isError) {
        if (res.data && res.data.length > 0) {
          setAttendee([]);
          members.map((family) => {
            if (res.data.some((item) => family.id === item.familyMemberId)) {
              const item = res.data.find(
                (item) => family.id === item.familyMemberId
              );

              setAttendee((attendees) => [
                ...attendees,
                {
                  userId: family.id,
                  name: family.name,
                  isBooked: true,
                  isParent: false,
                  status: item.status,
                },
              ]);
            } else {
              setAttendee((attendees) => [
                ...attendees,
                {
                  userId: family.id,
                  name: family.name,
                  isBooked: false,
                  isParent: false,
                },
              ]);
            }
          });
        } else {
          setAttendee([]);
          members.map((family) => {
            setAttendee((attendees) => [
              ...attendees,
              {
                userId: family.id,
                name: family.name,
                isBooked: false,
                isParent: false,
              },
            ]);
          });
        }
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="card text-left bg-primary border rounded-md">
        <div className="card-body flex justify-between max=h-20 p-1">
          <p className="card-text text-gold-1">{parent.name}</p>
          {parent.isBooked ? (
            <span className="bg-gold-100 border text-sm rounded w-fit px-5 py-1 text-cyan-50">
             {parent.status=== 'pre-registered'? 'Waiting List': parent.status}
            </span>
          ) : (
            <a
              href="#"
              onClick={() => bookCamp(parent)}
              className="bg-gold-100 border text-sm rounded w-fit px-5 py-1 text-cyan-50"
            >
              Register
            </a>
          )}
        </div>
      </div>
      {attendees.map((attende, i) => {
        return (
          <div key={i} className="card text-left bg-primary border rounded-md">
            <div className="card-body flex justify-between max=h-20 p-1">
              <p className="card-text text-gold-1">{attende.name}</p>
              {attende.isBooked ? (
                <span className="bg-gold-100 border text-sm rounded w-fit px-5 py-1 text-cyan-50">
                  {attende.status=== 'pre-registered'? 'Waiting List': attende.status}
                </span>
              ) : (
                <a
                  href="#"
                  onClick={() => bookCamp(attende)}
                  className="bg-gold-100 border text-sm rounded w-fit px-5 py-1 text-cyan-50"
                >
                  Register
                </a>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default CampAttendess;
