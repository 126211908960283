import config from "../config";
const users = "users";
const familyMembers = "family-members";
const bearer_token = config.AUTH.DRIVER.getItem("token");
const bearer = "Bearer " + bearer_token;
const getHeaderOptions = {
  method: "GET",
  headers: { "Content-Type": "application/json", Authorization: bearer },
};
export async function ppService(data) {
  try {
    const response = await fetch(`${config.AUTH.API_HOST}${users}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function patchUserService(data) {
  const bearer_token = config.AUTH.DRIVER.getItem("token");
  const bearer = "Bearer " + bearer_token;
  try {
    const response = await fetch(`${config.AUTH.API_HOST}${users}/${data.id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json",  Authorization: bearer },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getUserInfo(id) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${users}/${id}`,
      getHeaderOptions
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}
export async function getFamilyInfo(id) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${familyMembers}/?userId=${id}`,
      getHeaderOptions
    );
    return await response.json();
  } catch (error) {
    return [];
  }
} 

export async function postFamilyMember(data) {
  const bearer_token = config.AUTH.DRIVER.getItem("token");
const bearer = "Bearer " + bearer_token;
  const response = await fetch(`${config.AUTH.API_HOST}${familyMembers}`, {
    method: "POST",
    headers: { "Content-Type": "application/json",  Authorization: bearer},
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function patchFamilyMember(data) {
  const bearer_token = config.AUTH.DRIVER.getItem("token");
const bearer = "Bearer " + bearer_token;
  const response = await fetch(`${config.AUTH.API_HOST}${familyMembers}/${data.id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json",  Authorization: bearer},
    body: JSON.stringify(data),
  });
  return await response.json();
}