import config from "../config";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

export const toast = (type, message) => {
  (function () {
    MySwal.fire({
      icon: type,
      title: message,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        var toast = MySwal.getContainer();
        toast.addEventListener("mouseenter", MySwal.stopTimer);
        toast.addEventListener("mouseleave", MySwal.resumeTimer);
      },
    }).then((toast) => {});
  })();
};

export const confirmAlert = (executing, confirmButtonText = "Yes, delete!") => {
  MySwal.fire({
    title: "Are you sure?",
    text: "You can't go back !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#ef4444",
    cancelButtonColor: "#52525b",
    confirmButtonText: confirmButtonText,
    cancelButtonText: "Cancel",
    showLoaderOnConfirm: true,
    preConfirm: executing,
  }).then((result) => {
    if (result.isConfirmed) {
      // toast('success', "Succès de la suppression");
    }
  });
};

export const checkPermissions = (permissions = []) => {
  let found = false;
  const userPermissions = ["add", "update", "delete"];
  if (userPermissions) {
    permissions.forEach((item) => {
      found = found || userPermissions.includes(item);
    });
  }
  return found;
};

export const scrollTop = () => {
  const element = document.getElementById("top-page");
  if (element) {
    const position = getOffset(element);
    window.scrollTo(position.left, 0);
  }
};

export const formatToCurrency = (value) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "XOF",
  }).format(parseFloat(value));
};

export const checkUser = () => {
  return config.AUTH.DRIVER.getItem("user") ? true : false;
};

export const logout = (navigate) => {
  config.AUTH.DRIVER.removeItem("user");
  config.AUTH.DRIVER.removeItem("token");
  navigate("/", { replace: true });
  toast("success", "Successful logout");
};
