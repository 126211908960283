import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { RiFacebookCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../components/buttons";
import { Checkbox, Input } from "../components/field";
import { Link, Loader } from "../components/utils";
import config from "../config";
import { toast } from "../helpers";
import AuthLayout from "../layouts/AuthLayout";
import { resetPasswordService } from "../services/Authentication";

const ResetPassword = () => {
  const navigate = useNavigate();
  const defaultMessage = {
    password: [],
    confrimPassword: [],
  };

  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultMessage);
  const [password, setPassword] = useState("");
  const [confrimPassword, setConirmPassword] = useState("");
  //   toast("error", "Connection failed");
  //   setErrorMessage(newErrorMessage);
  const resetPass = () => {
    const newErrorMessage = defaultMessage;
    setInvalid(false);
    if (!password) {
      setLoading(false);
      if (!password) {
        newErrorMessage.password = ["password is required"];
      }
      if (!confrimPassword) {
        newErrorMessage.confrimPassword = ["confrimPassword  is required"];
      }
      if (password !==confrimPassword) {
        newErrorMessage.confrimPassword = ["password && confrimPassword not mtacch"];
      }
 
      setInvalid(true);
    } else {
      setLoading(true);
      setInvalid(false);

      if (password) {
        const fields = {
          password
          : password
        };
        let title = "";
        let success = "";
        resetPasswordService(fields).then((res) => {
          if (!res.isError) {
            setLoading(false);
            title = "success";
            success = "Password reset successfull please login "; 
            navigate("/login");
          } else {
            title = "error";
            success = res.message;
          }
          toast(title, success);
          setLoading(false);
        });
      }
    }
  };

  return (
    <AuthLayout
      title={
        <>
          Welcome back to <br /> our community
        </>
      }>
      <h3 className="text-center text-xl font-semibold text-gray-700">
       Reset password
      </h3>
      <p className="text-center text-sm mt-2 mb-10">
        Please update your new password
      </p>

      {invalid && (
        <div className="my-2 text-center text-red-600 bg-red-100 py-2 rounded-md">
          Invalid email  
        </div>
      )}

      <form className="space-y-5">
       
        <div>
          <Input
            label={"Password"}
            id="password"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errorMessage.password}
          />
        </div>
        <div>
          <Input
            label={"Confrim Password"}
            id="confrimPassword"
            type="password"
            placeholder="Enter confrim password"
            value={confrimPassword}
            onChange={(e) => setConirmPassword(e.target.value)}
            error={errorMessage.confrimPassword}
          />
        </div>

        <PrimaryButton onClick={resetPass} disabled={loading}>
          {loading && <Loader color={"white"} />}
          <span>Update Password</span>
        </PrimaryButton>

        
       
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
