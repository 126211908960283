import { Container } from "../components/utils";
import Banner from "../layouts/Banner";
import Nav from "../layouts/Nav";
import CountUp from "react-countup";
import Footer from "../layouts/Footer";
import About from "../layouts/About";
import CampServices from "../layouts/CampServices";
import Services from "../layouts/Services";
import { useEffect } from "react";
import { getCamps} from "../services/Meetings";
import { useState } from "react";
import BrRino from "../layouts/BrRino";
import ContactUs from "../layouts/ContactUs";

const Home = () => {
  const [meetings, setMeetings] = useState([]);
  useEffect(() => {
    getmeetings();
  }, []);
  const getmeetings = () => {
    getCamps().then((res) => {
      if (!res.isError) {
        setMeetings(res.data);
      }
    });
  };
  return (
    <div>
      <Nav />

      <Banner />

      <Container className="wow fadeInUp relative -mt-[110px]">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white border border-gray-200 border-opacity-50 shadow-2xl rounded-xl">
          <div className="text-center py-10 lg:py-16 space-y-3 text-gold-100 font-medium">
            <h1 className="text-5xl lg:text-6xl font-semibold text-gold-100">
              <CountUp end={100} />
              K+
            </h1>
            <p className="text-sm lg:text-base uppercase">Camp Attendee</p>
          </div>

          <div className="text-center py-10 lg:py-16 space-y-3 text-gold-100 font-medium">
            <h1 className="text-5xl lg:text-6xl font-semibold text-gold-100">
              <CountUp end={7} />+
            </h1>
            <p className="text-sm lg:text-base uppercase">
              {" "}
              Monthly One Day Prayers
            </p>
          </div>

          <div className="text-center py-10 lg:py-16 space-y-3 text-gold-100 font-medium">
            <h1 className="text-5xl lg:text-6xl font-semibold text-gold-100">
              <CountUp end={25} />+
            </h1>
            <p className="text-sm lg:text-base uppercase">
              Weekly Zoom Prayers
            </p>
          </div>

          <div className="text-center py-10 lg:py-16 space-y-3 text-gold-100 font-medium">
            <h1 className="text-5xl lg:text-6xl font-semibold text-gold-100">
              <CountUp end={7} />/<CountUp end={24} />
            </h1>
            <p className="text-sm lg:text-base uppercase">Prayer Support</p>
          </div>
        </div>
      </Container>
      
      {meetings.length > 0 && <CampServices meetings={meetings} />}

      <About />

      <Services />
      <BrRino />
      {/* <Platforms /> */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
