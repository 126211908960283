import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BrRino from "./pages/BrRino";
import Camps from "./pages/Camps";

import Home from "./pages/Home";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyOtp from "./pages/VerifyOtp";
import OneDay from "./pages/OneDay";
// import Profile from "./pages/Profile";
import Register from "./pages/Register";
import DirectBooking from "./pages/DirectBooking";
import UserProfile from "./pages/UserProfile";
import Zoom from "./pages/Zoom";
import ResetPassword from "./pages/ResetPassword";
import PrayerRequest from "./pages/PrayerRequest";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verification" element={<VerifyOtp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/direct-booking/:id" element={<DirectBooking />} />
        <Route path="/zoom-prayers" element={<Zoom />} />
        <Route path="/camps" element={<Camps />} />
        <Route path="/oneday-prayers" element={<OneDay />} />
        <Route path="/br-rino" element={<BrRino />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/prayer-request" element={<PrayerRequest />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
