import { Container, Loader } from "../components/utils";
import Nav from "../layouts/Nav";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
import { getZoomMeetings } from "../services/Meetings";
import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const Zoom = () => {
  const [zoomMeetings, setZoomMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getzoomprayers();
  }, []);
  const getzoomprayers = () => {
    setLoading(true);
    getZoomMeetings().then((res) => {
      if (!res.isError) {
        setZoomMeetings(res.data);
      }
      setLoading(false);
    });
  };
  return (
    <div>
      <Nav />

      <section className="w-full overflow-hidden  wow fadeInUp">
        <div className="relative bg-cover bg-no-repeat ">
          <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
            <Container className="pt-5">
              <div className="flex flex-col gap-10 justify-center">
                <div className="flex flex-col items-center justify-center ">
                  <h3 className="text-3xl lg:text-5xl text-black leading-snug font-semibold">
                    Zoom Prayer Meetings
                  </h3>
                  <p className="text-base text-gold-1 mt-4 text-center lg:max-w-xl">
                    By the grace of God Our team conducting online prayer
                    meeting across the world. We welcome you to attend our
                    prayer meeting in Jesus Name. Please find your nearby zoom
                    Meeting Id and Please contact our coordinators for any
                    enquiries
                  </p>
                  {loading && <Loader color={"black"} size={"2xl"} />}
                </div>

                <div className="items-center w-full">
                  <div className="grid gap-6 lg:grid-cols-3 lg:grid-rows-2">
                    {zoomMeetings.map((meeting, i) => {
                      return (
                        meeting.statusId === 1 && (
                          <div
                            key={i}
                            className="bg-gold-10 max-h-72 p-5 overflow-hidden justify-between flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 shadow-sm hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                            <div>
                              <h3 className="text-xl text-gray-900 font-medium">
                                {meeting.title}
                              </h3>
                              <h3 className="text-xl text-gray-600 font-medium">
                                {meeting?.day?.name}
                              </h3>
                              <h4> Zoom ID : {meeting.zoomId}</h4>
                              <p className="text-sm text-black  whitespace-pre-wrap max-h-20 overflow-hidden">
                                {meeting.description}
                              </p>
                            </div>
                            <div>
                              <a
                                href={meeting.zoomLink}
                                className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50">
                                {" "}
                                Join
                              </a>
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Zoom;
