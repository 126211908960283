import config from "../config";
const meetings = "meetings";

export async function getCamps(status = 1) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${meetings}?isCamp=true&statusIds=${status}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getCampByID(id) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${meetings}/${id}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}
export async function getZoomMeetings() {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${meetings}?isZoom=true&statusId=1`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}
export async function getOneDayMeetings() {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${meetings}?isOneDay=true&statusId=1`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}
export async function postContactUs(data) {
  const response = await fetch(`${config.AUTH.API_HOST}/contacts`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

 
export async function getUserCamps(userId, meetingId) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}attendees?userId=${userId}&meetingId=${meetingId}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getFamilyCamps(userId, meetingId) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}attendees?parentId=${userId}&meetingId=${meetingId}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function postAttende(data) {
  const response = await fetch(`${config.AUTH.API_HOST}attendees`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}
 
