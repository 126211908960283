import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../components/buttons";
import { Checkbox, Input, RadioButton } from "../components/field";
import { Link, Loader } from "../components/utils";
import { toast } from "../helpers";
import config from "../config";

import AuthLayout from "../layouts/AuthLayout";
import { registrationService } from "../services/Authentication";

const Register = () => {
  const navigate = useNavigate();
  const defaultMessage = {
    email: [],
    password: [],
    telephone: [],
  };
  const plans = ["startup", "business", "enterprise"];
  const [loading, setLoading] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [fullname, setFullname] = useState("");
  const [invalid, setInvalid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(defaultMessage);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("male");

  const register = () => {
    const newErrorMessage = defaultMessage;
    if (!email) {
      newErrorMessage.email = ["This field is required"];
    }
    if (!fullname) {
      newErrorMessage.fullname = ["This field is required"];
    }
    if (!password) {
      newErrorMessage.password = ["This field is required"];
    }
    if (!gender) {
      newErrorMessage.gender = ["This field is required"];
    }

    if (!telephone) {
      newErrorMessage.telephone = ["This field is required"];
    }
    setInvalid(false);
    setLoading(true);

    if (fullname && email && telephone && password && gender) {
      const fields = {
        email: email,
        password: password,
        phone: telephone,
        gender: gender,
        fullName: fullname,
        userRole: [
          {
            roleId: 9,
          },
        ],
      };
      let title = "";
      let success = "";
      registrationService(fields).then((res) => {
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Registration successfull";
          config.AUTH.DRIVER.setItem("user", JSON.stringify(res.data.user));
          config.AUTH.DRIVER.setItem("token", res.data.token);
          navigate("/profile");
        } else {
          title = "error";
          success = res.message;
        }
        toast(title, success);
        setLoading(false);
      });
    } else {
      setErrorMessage(defaultMessage);
    }
  };

  return (
    <AuthLayout
      title={
        <>
          Welcome to <br /> our community
        </>
      }>
      <h3 className="text-center text-xl font-semibold text-gray-700">
        Create New Account
      </h3>
      <p className="text-center text-sm mt-2 mb-10">
        Please sign-up to your account
      </p>

      <form className="space-y-5">
        <div>
          <Input
            label={"Name"}
            id="fullname"
            type="text"
            placeholder="Enter your full name"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            error={errorMessage.fullname}
          />
        </div>
        <div>
          <Input
            label={"Phone number"}
            id="phone_number"
            type="tel"
            placeholder="Enter phone number"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            error={errorMessage.telephone}
          />
        </div>

        <div>
          <Input
            label={"Email"}
            id="email"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage.email}
          />
        </div>
        <div>
          <RadioButton
            id="male"
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            label="Male"
            checked={gender === "male" ? true : false}
          />
          <RadioButton
            id="female"
            onChange={(e) => setGender(e.target.value)}
            name="gender"
            label="Female"
            checked={gender === "female" ? true : false}
            error={errorMessage.gender}
          />
        </div>
        <div>
          <Input
            label={"Password"}
            id="password"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errorMessage.password}
          />
        </div>

        {/* <div>
          <span className="text-sm cursor-pointer">
            By Submit, I agree to the privacy policy & terms and conditions
          </span>
        </div> */}

        <PrimaryButton onClick={register}>
          {loading && <Loader color={"white"} />}
          <span>Sign up</span>
        </PrimaryButton>

        <div className="flex items-center justify-center space-x-3">
          <hr className="w-12" />
          <span className="font-bold uppercase text-xs text-gray-400">Or</span>
          <hr className="w-12" />
        </div>

        {/* <div className="flex items-center space-x-4 lg:space-x-2 xl:space-x-4 text-sm font-semibold">
          <SecondaryButton as="a" href="#auth-google">
            <FcGoogle className="h-5 w-5 lg:w-4 lg:h-4 xl:h-5 xl:w-5" />

            <span className="text-[0.7rem] md:text-sm lg:text-[0.7rem] xl:text-sm">
              Continue with Google
            </span>
          </SecondaryButton>

          <SecondaryButton as="a" href="#auth-facebook">
            <RiFacebookCircleFill className="h-5 w-5 lg:w-4 lg:h-4 xl:h-5 xl:w-5 text-blue-600" />

            <span className="text-[0.7rem] md:text-sm lg:text-[0.7rem] xl:text-sm">
              Continue with Facebook
            </span>
          </SecondaryButton>
        </div> */}

        <p className="text-sm text-center">
          Already have an account? <Link href="/login">Login</Link>
        </p>
      </form>
    </AuthLayout>
  );
};

export default Register;
