import { Link } from "react-router-dom";
import { Container } from "../components/utils";
const Services = () => {
  return (
    <Container className="wow fadeInUp flex items-center flex-col-reverse xl:flex-row xl:space-y-0 xl:space-x-5">
      <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-6 text-pelorous-900">
        <Link to="/camps">
          <div className="transition-all duration-300 border border-pelorous-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
            <div className="flex items-center justify-center">
              <img src="../assets/img/s1.webp" alt="" />
            </div>
            <h3 className="text-xl lg:text-2xl font-bold text-pelorous-900">
              Deliverance Camp
            </h3>
            <p className="max-w-lg">
              Those who are under the influence of satanic attack there is
              deliverance in Jesus Name. we are conducting deilvernce camps
              across India and abroad. Please contact our office number if you
              liked to participate
            </p>
          </div>
        </Link>

        <div className="transition-all duration-300 border border-pelorous-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
          <div className="flex items-center   justify-center">
            <img src="../assets/img/s2.webp" alt="" />
          </div>
          <h3 className="text-xl lg:text-2xl font-bold text-pelorous-900">
            Spiritual Advising
          </h3>
          <p className="max-w-lg">
            If you need any guidence in your spiritual life, our beloved team
            members are here to help you please contact the office phone number
            for more details
          </p>
        </div>

        <div className="transition-all duration-300 border border-pelorous-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
          <div className="flex items-center  justify-center">
            <img src="../assets/img/s3.webp" alt="" />
          </div>
          <h3 className="text-xl lg:text-2xl font-bold text-pelorous-900">
            Youth Meetings
          </h3>
          <p className="max-w-lg">
            Monthly meetings are conducting for youth for their spirtual journey
            to become disciples of Jesus
          </p>
        </div>
        <Link to="zoom-prayers">
          <div className="transition-all duration-300 border border-pelorous-200 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
            <div className="flex items-center justify-center">
              <img src="../assets/img/s4.webp" alt="" />
            </div>
            <h3 className="text-xl lg:text-2xl font-bold text-pelorous-900">
              Zoom Meetings
            </h3>
            <p className="max-w-lg">
              World wide prayer meeting are conducting weekly. Please check our
              zoom meeting page to know more about you near location and send
              your parayer request to the numbers.
            </p>
          </div>
        </Link>
      </div>
    </Container>
  );
};
export default Services;
