import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Loader } from "../components/utils";
import { Checkbox } from "../components/field";
import { patchUserService } from "../services/usersService";
import { toast } from "../helpers/index";
import config from "../config";
import PhoneInput, { isValidPhoneNumber,  parsePhoneNumber } from "react-phone-number-input";
const ProfileModal = ({ memberInfo, getUserDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({...memberInfo});
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneValue] = useState (memberInfo.phoneExt + memberInfo.phone);
  const [hasWhatsapp, setHasWhatsappValue] = useState(memberInfo.hasWhatsappMembers);

  const handleInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUserData()
  }, []);

  const getUserData = () =>{
    const userInfo = JSON.parse(config.AUTH.DRIVER.getItem("user"));
    setUser(userInfo);
  }

  const onSubmit = async () => {
    setLoading(true);
    setPhoneError(false);
    const paraseNumber = parsePhoneNumber(phone)
    if (phone && isValidPhoneNumber(phone)) {  
      const fields = {
        id: user.id,
        fullName: user.fullName,
        gender: user.gender,
        email: user.email,
        hasWhatsappMembers: hasWhatsapp,
        phone: paraseNumber.nationalNumber,
        phoneExt: '+'+paraseNumber.countryCallingCode,
        place: user.place,
        address: user.address,
        age: user.age+"",
        campCount: +user.campCount,
        bloodGroup: user.bloodGroup,
        countryId: user.countryId,
        year: +user.year,
        phoneNumber: user.phoneNumber
      }; 

      patchUserService(fields).then((res) => {
        let title = "";
        let success = "";
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Profile Updated Sucessfully";  
          setShowModal(false);
          setLoading(false);
          getUserDetails(user.id);
          getUserData();
        } else {
          title = "error";
          success = "Something went wrong. Please try again";
        }
        toast(title, success);
        setLoading(false);
      });
    } else {
      setPhoneError(true);
      setLoading(false);
    }
  };
  return (
    <>
      <a
        href="#!"
        onClick={() => setShowModal(true)}
        className="border-gold-100 border text-sm rounded w-fix px-2  text-black"
      >
        View Profile
      </a>

      {showModal ? (
        <>
          <div className="flex bg-gray-800 bg-opacity-70 justify-center items-start overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-3/4 my-6 mx-auto ">
              <div className="p-10 shadow-2xl w-full  lg:absolute z-10 bg-white">
                <div className="">
                  <div>
                    <h4 className="text-xl text-black font-medium py-3 leading-8">
                      Edit Profile
                    </h4>
                  </div>
                  <div className=" ">
                  <div className="">
                        <div className="space-y-6 bg-white  py-5 ">
                          <div className="grid grid-cols-6 gap-6">
                            {/* Full Name */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="fullName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                name="fullName" 
                                placeholder="Full Name" 
                                value={user.fullName}
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300 `}
                              />
                              {/* {errors.fullName && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your full name
                                </span>
                              )} */}
                            </div>
                            {/* Age */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="fullName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Age
                              </label>
                              <input
                                type="text"
                                name="age"
                                key="age"
                                id="age"
                                placeholder="Age"
                                onChange={handleInput}
                                value={user.age}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm  border-gray-300  `}
                              />
                              {/* {errors.age && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your age
                                </span>
                              )} */}
                            </div>
                            {/* Email */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                key="email"
                                id="email"
                                value={user.email} 
                                onChange={handleInput}
                                placeholder="email"
                                // aria-invalid={
                                //   errors.fullName ? "true" : "false"
                                // }
                               
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-30 `}
                              />
                              {/* {errors.email && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your email
                                </span>
                              )} */}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Gender
                              </label>
                              <select
                                id="gender"
                                name="gender"
                                placeholder="Choose a Gender"
                                autoComplete="gender"
                                value={user.gender} 
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md border  border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}
                              >
                                <option value="male">MALE</option>
                                <option value="female">FEMALE</option>
                              </select>
                            </div>
                            {/* Place */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="place"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Place
                              </label>
                              <input
                                type="text"
                                name="place"
                                key="place"
                                id="place"
                                placeholder="place" 
                                value={user.place} 
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300` }
                              />
                               {/* ${
                                  errors.place
                                    ? " border-red-600"
                                    : " border-gray-300"
                                } */}
                              {/* {errors.place && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your place
                                </span>
                              )} */}
                            </div>

                             {/* Year */}
                             <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="place"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Year of Join
                              </label>
                              <input
                                type="text"
                                name="year"
                                key="year"
                                id="year"
                                placeholder="year" 
                                value={user.year ? user.year: ''} 
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300` }
                              />
                               {/* ${
                                  errors.place
                                    ? " border-red-600"
                                    : " border-gray-300"
                                } */}
                              {/* {errors.place && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your place
                                </span>
                              )} */}
                            </div>

                             {/* campCount */}
                             <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="place"
                                className="block text-sm font-medium text-gray-700"
                              >
                               No Of Camps
                              </label>
                              <input
                                type="text"
                                name="campCount"
                                key="campCount"
                                id="campCount"
                                placeholder="No Of Camps" 
                                value={user.campCount ? ""+user.campCount: ''} 
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300` }
                              />
                               {/* ${
                                  errors.place
                                    ? " border-red-600"
                                    : " border-gray-300"
                                } */}
                              {/* {errors.place && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your place
                                </span>
                              )} */}
                            </div>

                             {/* bloodGroup */}
                             <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="place"
                                className="block text-sm font-medium text-gray-700"
                              >
                              Blood Group
                              </label>
                              <input
                                type="text"
                                name="bloodGroup"
                                key="bloodGroup"
                                id="bloodGroup"
                                placeholder="Blood Group" 
                                value={user.bloodGroup ? user.bloodGroup: ''} 
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300` }
                              />
                               {/* ${
                                  errors.place
                                    ? " border-red-600"
                                    : " border-gray-300"
                                } */}
                              {/* {errors.place && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your place
                                </span>
                              )} */}
                            </div>

                            {/* address */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Address
                              </label>
                              <input
                                type="text"
                                name="address" 
                                id="address"
                                placeholder="address"
                                // aria-invalid={
                                //   errors.fullName ? "true" : "false"
                                // }
                                value={user.address}
                                onChange={handleInput}
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300`}
                              />
                              {/* ${
                                  errors.address
                                    ? " border-red-600"
                                    : " "
                                } */}
                              {/* {errors.address && (
                                <span
                                  role="alert"
                                  className=" text-red-500 text-xs"
                                >
                                  Please enter your address
                                </span>
                              )} */}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="enqFor"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone number
                              </label>

                              <PhoneInput
                                className={`${
                                  phone
                                    ? isValidPhoneNumber(phone)
                                      ? " border-red-600"
                                      : " border-gray-300"
                                    : ""
                                }`}
                                international={true} 
                                defaultCountry="IN"
                                country='IN'
                                placeholder="Enter phone number"
                                value={phone}
                                error={
                                  phone
                                    ? isValidPhoneNumber(phone)
                                      ? undefined
                                      : "Invalid phone number"
                                    : "Phone number required"
                                } 
                                onChange={(value)=> { 
                                  setPhoneValue(value)

                                }}
                              />
                              {phoneError && (
                                <span
                                  role="alert"
                                  className="text-red-500 text-xs row-span-5"
                                >
                                  Please enter your phone number
                                </span>
                              )}
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phoneNumber"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Second/Whatsapp Phone Number
                              </label>
                              <input
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={user.phoneNumber}
                                onChange={handleInput}
                                placeholder="Second/Whatsapp Phone Number"
                                className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm `}
                              />
                              {/* {errors.phoneNumber && (
                                <span
                                  role="alert"
                                  className="col-span-5 text-red-500 text-xs"
                                >
                                  Please enter your Second Phone Number
                                </span>
                              )} */}
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Checkbox
                                label="Has Whatsapp"
                                value = {hasWhatsapp}
                                onChange={(e) => {
                                  setHasWhatsappValue(e.target.checked);
                                }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="py-3 flex gap-4">
                          <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-red-400 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <span>Cancel</span>
                            {loading && <Loader color={"white"} />}
                            <AiOutlineCloseCircle size={20} />
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-gold-100 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={onSubmit}
                          >
                            <span>Submit</span>
                            {loading && <Loader color={"white"} />}
                            <BsArrowRight size={20} />
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProfileModal;
