import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsArrowRight } from "react-icons/bs";
import { Container, Loader } from "../components/utils";
import { toast } from "../helpers/index";
import Nav from "../layouts/Nav";
import Footer from "../layouts/Footer";
import { Helmet } from 'react-helmet';
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { postContactUs } from "../services/Meetings";
import { useNavigate } from "react-router-dom";
const PrayerRequest = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhoneValue] = useState();
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setPhoneError(false);
    if (phone && isValidPhoneNumber(phone)) {
      const fields = {
        name: data.fullname,
        email: data.email,
        phone: phone,
        message: data.message,
        place: data.place,
        subject: data.enqFor,
      };

      postContactUs(fields).then((res) => {
        let title = "";
        let success = "";
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Thank you for your message. We will contact you soon";
          setPhoneValue("");
          document.getElementById("formcontact").reset();
          navigate("/zoom-prayers");

        } else {
          title = "error";
          success = "Something went wrong. Please try again";
        }
        toast(title, success);
        setLoading(false);
      });
    } else {
      setPhoneError(true);
      setLoading(false);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Prayer Request</title>
        <meta name="description" content="Trust in the Lord with all thine heart; and lean not unto thine own understanding.  In all thy ways acknowledge Him, and He shall direct thy paths." />
        <meta name="keywords" content="payer, submit, depression, sad, jesus" />
        <meta name="author" content="Jesus Vision" />
        <meta property="og:title" content="Prayer Request" />
        <meta property="og:description" content="This is a description of my page" />
        <meta property="og:image" content="https://res.cloudinary.com/dd0b5dye0/image/upload/v1707319101/logored_iadlmq.png" />
        <meta property="og:url" content="https://jesusvision.in/prayer-request" />
        <meta name="twitter:title" content="Jesus Vision" />
        <meta name="twitter:description" content="Trust in the Lord with all thine heart;" />
        <meta name="twitter:image" content="https://res.cloudinary.com/dd0b5dye0/image/upload/v1679906696/logo3_tjcjbl.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    <section
      id="contact"
      className="w-full  bg-white wow fadeInUp my-[5rem]  overflow-visible">
      <Container className=" lg:mb-40 xl:mb-52">
        <div className="grid gap-10 lg:grid-cols-1  ">
          <div className=" overflow-visible relative justify-center ">
            <div className="p-10 shadow-2xl w-full  lg:absolute z-10 bg-white">
              <div className="">
                <div>
                <h4 className="text-xl text-black text-align: center; align-content: center; font-medium  leading-8">
                  WELCOME TO JESUS VISION DELIVERANCE MINISTRY
                  </h4>
                <p className="py-4 text-base text-black leading-7 	font-style: italic;">
                Philippians 4:6:&nbsp;Do not be anxious about anything, but in everything by prayer and supplication with thanksgiving let your requests be made known to God 
              </p>
               
                 
                  <p className="py-4 text-base text-gold-1 leading-7">
                Please send us your message and we will contact you soon...
              </p>
                </div>
                <div className=" ">
                  <form id="formcontact" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="space-y-6 bg-white  py-5 ">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="fullname"
                              className="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              name="fullname"
                              key="fullname"
                              placeholder="Full Name"
                              aria-invalid={errors.fullname ? "true" : "false"}
                              {...register("fullname", {
                                required: true,
                              })}
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                errors.fullname
                                  ? " border-red-600"
                                  : " border-gray-300"
                              }`}
                            />
                            {errors.fullname && (
                              <span
                                role="alert"
                                className=" text-red-500 text-xs">
                                Please enter your full name
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              aria-invalid={errors.email ? "true" : "false"}
                              {...register("email", {
                                required: false,
                              })}
                              autoComplete=" "
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                                errors.email
                                  ? " border-red-600"
                                  : " border-gray-300"
                              }`}
                            />
                            {errors.email && (
                              <span
                                role="alert"
                                className="text-red-500 text-xs">
                                Please enter your Email
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="enqFor"
                              className="block text-sm font-medium text-gray-700">
                              Phone number
                            </label>

                            <PhoneInput
                              className={`${
                                phone
                                  ? isValidPhoneNumber(phone)
                                    ? " border-red-600"
                                    : " border-gray-300"
                                  : ""
                              }`}
                              international={false}
                              defaultCountry="IN"
                              placeholder="Enter phone number"
                              error={
                                phone
                                  ? isValidPhoneNumber(phone)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                              value={phone}
                              onChange={setPhoneValue}
                            />
                            {phoneError && (
                              <span
                                role="alert"
                                className="text-red-500 text-xs row-span-5">
                                Please enter your phone number
                              </span>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700">
                              Place
                            </label>
                            <input
                              type="text"
                              name="place"
                              id="place"
                              {...register("place", {
                                required: true,
                              })}
                              placeholder="Location"
                              autoComplete=" "
                              className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm `}
                            />
                          </div>
                          {errors.place && (
                            <span
                              role="alert"
                              className="col-span-5 text-red-500 text-xs">
                              Please enter your present location
                            </span>
                          )}
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="enqFor"
                            className="block text-sm font-medium text-gray-700">
                            Contact Request For
                          </label>
                          <select
                            id="enqFor"
                            name="enqFor"
                            placeholder="Choose a Topic"
                            autoComplete="enqFor"
                            {...register("enqFor", {
                              required: true,
                            })}
                            className={`mt-1 block w-full rounded-md border  border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}>
                            <option value="general">General</option>
                            <option value="prayerRequest">
                              Prayer Request
                            </option>
                            <option value="campEnquiry">Camp booking</option>
                            <option value="spiritualAdviceRequest">
                              Spiritual Advising
                            </option>
                          </select>
                        </div>

                        <div>
                          <label
                            htmlFor="message"
                            className="block text-sm font-medium text-gray-700">
                            Message / Request
                          </label>
                          <div className="mt-1">
                            <textarea
                              id="message"
                              name="message"
                              {...register("message", {
                                required: true,
                              })}
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm"
                              placeholder="Please enter your message here"
                              defaultValue={""}
                            />
                          </div>
                          {errors.message && (
                            <span role="alert" className="text-red-500 text-xs">
                              Please enter your message
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="py-3">
                        <button
                          type="submit"
                          className="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-gold-100 py-2 px-5 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2">
                          <span>Submit</span>
                          {loading && <Loader color={"white"} />}
                          <BsArrowRight size={20} />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
     <Footer />
     </div>
  );
};
export default PrayerRequest;