import config from "../config";
const authentication = "authentication";

export async function loginService(data) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${authentication}/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}


export async function resetPasswordService(data) {
  const token = config.AUTH.DRIVER.getItem("token");
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${authentication}/resetPassword`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({...data, token}),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function verifyOtpService(data) {
  try {
    const token = config.AUTH.DRIVER.getItem("token");
    const response = await fetch(
      `${config.AUTH.API_HOST}${authentication}/verifyOtp`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({...data, token}),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function forgotPasswordService(data) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${authentication}/forgotpassword`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function registrationService(data) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}${authentication}/signup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}



export async function directBookingService(data) {
  try {
    const response = await fetch(
      `${config.AUTH.API_HOST}direct-participants`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    return await response.json();
  } catch (error) {
    return [];
  }
}