import { Container } from "../components/utils";
import { Link } from "react-router-dom";
const BrRino = () => {
  return (
    <section className="w-full overflow-hidden bg-white wow fadeInUp my-[5rem]">
      <Container className="">
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-2   py-20">
          <div className="flex flex-col justify-between">
            <div>
              <h1 className="text-5xl text-black leading-snug font-semibold">
                Br. Rino Jacob
              </h1>
              <h3>1982 - 2020</h3>
              <p className="py-4 text-base text-gold-1">
                Br.Rino was born on October 20, 1982 in Thuravoor,Angamaly.He is
                the eldest son of Jacob and Mary. Rino, had abdominal pain since
                2004,so he went on to take painkillers. <br /> Later he was
                &nbsp;{" "}
                <Link to="/br-rino" className="text-gold-100">
                  Read More...
                </Link>
              </p>
            </div>
          </div>
          <div className="flex items-start justify-center -order-1 lg:order-first">
            <img
              className=" w-64 rounded-full shadow-2xl"
              src="./assets/img/brRino.jpg"
              alt="BrRino"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
export default BrRino;
