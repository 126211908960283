import { Container } from "../components/utils";
const About = () => {
  return (
    <section className="w-full overflow-hidden bg-white wow fadeInUp my-[5rem]">
      <Container className="">
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-2  ">
          <div className="flex items-start justify-start">
            <img className="" src="./assets/img/jvdm-gold.png" alt="About" />
          </div>
          <div className="flex flex-col justify-between">
            <div>
              <h1 className="text-5xl text-black leading-snug font-semibold">
                JESUS VISION DELIVERANCE MINISTRY
              </h1>
              <p className="py-4 text-base text-gold-1">
                Was the first ministry in Kerala to conduct deliverance camps.
                <br />
                In 1998, camp was initially conducted in Punalur and later in
                Anchal. Since then, God has made it possible for the ministry to
                move on despite all kinds of hardships. With God's grace, our
                ministry has been able to spread the truth regarding satanic
                claims and other bondages to more than 55% Keralites all over
                the world. Our motive is to bring Christians from different
                churches and denominations together to teach them the gospel as
                well as to praise God but at the same time, we teach them to
                stand firm in their respective churches. We are totally against
                all superstitious beliefs, as we pray to God only in spirit and
                in truth exactly as Christ Jesus had taught us to. Jesus is the
                leader of this ministry and everything is done according to His
                will.
              </p>
            </div>
          </div>
        </div>
        <div className="grid gap-10 grid-cols-1 lg:grid-cols-2   py-20">
          <div className="flex flex-col justify-between">
            <div>
              <h1 className="text-5xl text-black leading-snug font-semibold">
                BR. SHIBU EALAYIL
              </h1>
              <h3>DIRECTOR OF JESUS VISION</h3>
              <p className="py-4 text-base text-gold-1">
                Evangelist Br Shibu Ealayil received the power of the Holy
                spirit in the year 1988 ,March 2 and preached the word of God in
                front of a massive Christian congregation by the blessings of
                the almighty God in Nedumkayam. Hence, began the Preaching
                devotional journey by spreading God's Word in churches, prayer
                halls and auditoriums. Since then, Br Shibu Ealayil spent 32
                glorious years in preaching the Gospel and started an Ecumenical
                prayer Group. In 1998, he studied about Bondage Deliverance and
                received the gift of the Holy Spirit through which he started a
                full time retreat center. Now he has completed 32 years in this
                Ministry, he preached and helped more than 100,000 believers to
                understand the Gospel and led them to Jesus, conducted various
                bible meetings across the world and Conducted Bible study trips
                to Different countries. Additionally, empowered more than 5000
                disciples to spread the word of God worldwide. Br Shibu Ealayil
                continues to practice the denomination of Christianity in which
                he was born.
              </p>
            </div>
            <div className="bg-gold-10 flex gap-5 flex-col  rounded-lg">
              <div className="flex flex-row gap-5 p-3">
                <div className="grid justify-center">
                  <p className="text-base text-gold-1">
                    "You need a special permission from your neighbour to praise
                    and glorify the God which they worship. However, to spread
                    the name of Jesus which is filled in my heart I only need
                    the will of my mind and soul" <br /> Br Shibu Ealayil
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-start -order-1 lg:order-last">
            <img className="" src="./assets/img/pp.jpg" alt="About" />
          </div>
        </div>
      </Container>
    </section>
  );
};
export default About;
