import { Container, Loader } from "../components/utils";
import Nav from "../layouts/Nav";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
import { getOneDayMeetings } from "../services/Meetings";
import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
const OneDay = () => {
  const [oneDayMeetings, setOneDayMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getoneDayMeetings();
  }, []);
  const getoneDayMeetings = () => {
    setLoading(true);
    getOneDayMeetings().then((res) => {
      setLoading(false);
      if (!res.isError) {
        setOneDayMeetings(res.data);
      }
    });
  };
  return (
    <div>
      <Nav />

      <section className="w-full overflow-hidden  wow fadeInUp mt-8">
        <div className="relative bg-cover bg-no-repeat ">
          <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
            <Container className="pt-5">
              <div className="flex flex-col gap-10 justify-center">
                <div className="flex flex-col items-center justify-center ">
                  <h3 className="text-3xl lg:text-5xl text-black leading-snug font-semibold">
                    One Day Prayer Meetings
                  </h3>
                  <p className="text-base text-gold-1 mt-4 text-center lg:max-w-xl">
                    Please find your nearby One Day Meeting Id and Please
                    contact our coordinators for any enquiries
                  </p>
                  {loading && <Loader color={"black"} size={"2xl"} />}
                </div>
                <div className="items-center w-full">
                  <div className="grid gap-6 lg:grid-cols-4 lg:grid-rows-2">
                    {oneDayMeetings.map((meeting) => {
                      return (
                        <div
                          key={meeting.id}
                          className="bg-gold-10 max-h-72 p-5 overflow-hidden flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                          <div className="  bg-gold-100 w-fit rounded-lg flex items-center justify-center"></div>
                          <h3 className="text-xl text-center text-black font-medium">
                            {meeting.title}
                          </h3>
                          <h4 className="text-base text-center text-black font-medium">
                            {meeting.description}
                          </h4>
                          <p className="text-sm text-gold-1 text-center  whitespace-pre-wrap max-h-20 overflow-hidden">
                            {meeting.locations.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default OneDay;
