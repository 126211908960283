import { Container, Loader } from "../components/utils";
import Nav from "../layouts/Nav";
import CampAttendess from "../layouts/CampAttendess";
import { useEffect } from "react";
import { getCamps, getZoomMeetings } from "../services/Meetings";
import { useState } from "react";
import config from "../config";
import { HiUser } from "react-icons/hi";

import { getFamilyInfo, getUserInfo } from "../services/usersService";
import { Tab } from "@headlessui/react";
import ProfileModal from "../modal/ProfileModal";
import MemberModal from "../modal/MemberModal";
import EditMemberModal from "../modal/EditMemberModal";
const UserProfile = () => {
  const [camps, setCamps] = useState([]);
  const [zoomMeetings, setZoomMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [family, setFamily] = useState([]);
  const [familyLoaded, setFamilyLoaded] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const userInfo = JSON.parse(config.AUTH.DRIVER.getItem("user"));
    setUser(userInfo);
    getCamp();
    getZoomPrayers();
    getFamilyMembers(userInfo.id);
    getUserDetails(userInfo.id);
  }, []);
  const getCamp = () => {
    setLoading(true);
    getCamps("[1,2,3,4,8]").then((res) => {
      setLoading(false);
      if (!res.isError) {
        setCamps(res.data);
      }
    });
  };
  const getZoomPrayers = () => {
    setLoading(true);
    getZoomMeetings().then((res) => {
      if (!res.isError) {
        setZoomMeetings(res.data);
      }
      setLoading(false);
    });
  };

  const getFamilyMembers = (id) => {
    getFamilyInfo(id).then(
      (res) => {
        if (res.code === 200) {
          setFamily(res.data);
          setFamilyLoaded(true);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getUserDetails = (id) => {
    getUserInfo(id).then(
      (res) => {
        if (res.code === 200) {
          setUser(res.data);
          config.AUTH.DRIVER.setItem("user", JSON.stringify(res.data));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div>
      <Nav />
      <section className="w-full overflow-hidden  wow fadeInUp">
        {loading && <Loader color={"black"} size={"2xl"} />}
        <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
          <Container>
            <div className="grid grid-cols-12 gap-10">
              <div id="sideMenu" className="col-span-3">
                <div className="flex flex-row h-screen w-full justify-center">
                  <div className="max-w-xs">
                    <div className="bg-white shadow-xl rounded-lg py-3">
                      <div className="photo-wrapper p-2">
                        <img
                          className="w-32 h-32 rounded-full mx-auto"
                          src="../../assets/img/user.png"
                          alt="John Doe"
                        />
                      </div>
                      <div className="p-2">
                        <h3 className="text-center text-xl text-gray-900 font-medium leading-8">
                          {user.fullName}
                        </h3>
                        <div className="text-center text-gray-400 text-xs font-semibold">
                          <p>{}</p>
                        </div>
                        <table className="text-xs my-3 ">
                          <tbody>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">
                                Phone
                              </td>
                              <td className="px-2 py-2">
                                {user.phoneExt} {user.phone}
                              </td>
                            </tr>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">
                                Email
                              </td>
                              <td className="px-2 py-2"> {user.email}</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">
                                Place
                              </td>
                              <td className="px-2 py-2">{user.place}</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-2 text-gray-500 font-semibold">
                                Gender
                              </td>
                              <td className="px-2 py-2">
                                {user.gender === "male" ? "Male" : "Female"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {user && user.id ? (
                          <ProfileModal
                            memberInfo={user}
                            getUserDetails={getUserDetails}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="details" className="col-span-9">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded bg-gold-10 px-1 shadow-md">
                    <Tab
                      key={"Profile"}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded py-2.5 text-sm font-medium leading-5 ",
                          "ring-white ring-opacity-60   focus:outline-none text-black",
                          selected
                            ? " border-b-4 border-gold-100 shadow "
                            : "text-white hover:bg-white/[0.12] hover:text-gold-100"
                        )
                      }
                    >
                      Family Members
                    </Tab>
                    <Tab
                      key={"UpcomingCamps"}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded py-2.5 text-sm font-medium leading-5 ",
                          "ring-white ring-opacity-60   focus:outline-none text-black",
                          selected
                            ? " border-b-4 border-gold-100 shadow "
                            : "text-white hover:bg-white/[0.12] hover:text-gold-100"
                        )
                      }
                    >
                      Upcoming Camps
                    </Tab>
                    <Tab
                      key={"ZoomPrayers"}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded py-2.5 text-sm font-medium leading-5 ",
                          "ring-white ring-opacity-60   focus:outline-none text-black",
                          selected
                            ? " border-b-4 border-gold-100 shadow "
                            : "text-white hover:bg-white/[0.12] hover:text-gold-100"
                        )
                      }
                    >
                      Zoom Prayers
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    <Tab.Panel
                      key={"Profile"}
                      className={classNames(
                        "rounded-xl bg-white p-3",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                      )}
                    >
                      <div>
                        <div>
                          <MemberModal
                            memberInfo={null}
                            userId={user.id}
                            getFamilyMembers={getFamilyMembers}
                          />
                        </div>
                        <table className="w-full table-auto border-collapse border border-gold-100 px-3">
                          <thead>
                            <tr>
                              <th className="border border-gold-100 px-3  py-2">
                                Photo
                              </th>
                              <th className="border border-gold-100 px-3 py-2">
                                Name
                              </th>
                              <th className="border border-gold-100 px-3 py-2">
                                Relation
                              </th>
                              <th className="border border-gold-100 px-3 py-2">
                                Age
                              </th>
                              <th className="border border-gold-100 px-3 py-2">
                                Gender
                              </th>
                              <th className="border border-gold-100 px-3 py-2">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {family.length > 0 && (
                              <>
                                {family.map((member) => {
                                  return (
                                    <tr
                                      key={member.id}
                                      className="text-center justify-center items-center"
                                    >
                                      <td className=" flex justify-center px-3 py-2 ">
                                        <HiUser />
                                      </td>
                                      <td className="border border-gold-100 px-3 py-2">
                                        {member.name}
                                      </td>
                                      <td className="border border-gold-100 px-3 py-2">
                                        {member.relation}
                                      </td>
                                      <td className="border border-gold-100 px-3 py-2">
                                        {member.age}
                                      </td>
                                      <td className="border border-gold-100 px-3 py-2">
                                        {member.gender}
                                      </td>
                                      <td className="border border-gold-100 px-3 py-2">
                                        <EditMemberModal
                                          memberInfo={member}
                                          getFamilyMembers={getFamilyMembers}
                                        ></EditMemberModal>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel
                      key={"UpcomingCamps"}
                      className={classNames(
                        "rounded-xl bg-white p-3",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                      )}
                    >
                      {camps.length > 0 && familyLoaded && (
                        <div className="flex flex-col gap-2 border-b border-slate-400">
                          <h4> Upcoming Camps</h4>
                          <div className="grid gap-6 lg:grid-cols-3 lg:grid-rows-2">
                            {camps.map((camp) => {
                              return (
                                <div
                                  key={camp.id}
                                  className="bg-gold-10 max-h-72 p-5 rounded-xl overflow-hidden flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl"
                                >
                                  <h3 className="text-xl text-black font-medium">
                                    {camp.title}
                                  </h3>
                                  <p className="text-md text-black whitespace-pre-wrap max-h-20 overflow-hidden">
                                    {camp.description}
                                  </p>

                                  <p className="text-sm text-gold-1 whitespace-pre-wrap max-h-20 overflow-hidden">
                                    Please contact office for more details
                                  </p>
                                  {camp.statusId === 1 ? (
                                    <CampAttendess
                                      user={user}
                                      meetingId={camp.id}
                                      memberss={family}
                                    ></CampAttendess>
                                  ) : (
                                    <a
                                      href="!#"
                                      className=" bg-gray-200 border text-sm rounded w-full px-5 py-1 text-center text-black"
                                    >
                                      {camp.status.name}
                                    </a>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Tab.Panel>
                    <Tab.Panel
                      key={"ZoomPrayers"}
                      className={classNames(
                        "rounded-xl bg-white p-3",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                      )}
                    >
                      {zoomMeetings.length > 0 && (
                        <div className="flex flex-col gap-2 overflow-scroll">
                          <h4> Upcoming Prayers</h4>
                          <div className="grid gap-6 lg:grid-cols-3 lg:grid-rows-2">
                            {zoomMeetings.map((meeting) => {
                              return (
                                <div
                                  key={meeting.id}
                                  className="bg-gold-10 max-h-72 p-5 overflow-hidden justify-between flex flex-col gap-3 text-ellipsis col-span-2 lg:col-span-1 shadow-sm hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl"
                                >
                                  <div>
                                    <h3 className="text-xl text-gray-900 font-medium">
                                      {meeting.title}
                                    </h3>
                                    <h3 className="text-xl text-gray-600 font-medium">
                                      {meeting?.day?.name}
                                    </h3>
                                    <h4> Zoom ID : {meeting.zoomId}</h4>
                                    <p className="text-sm text-black  whitespace-pre-wrap max-h-20 overflow-hidden">
                                      {meeting.description}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      href={meeting.zoomLink}
                                      className="bg-gold-100 border bottom-1 text-sm rounded w-fit px-5 py-1 text-cyan-50"
                                    >
                                      {" "}
                                      Join
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </div>
  );
};
export default UserProfile;
