import { Container } from "../components/utils";
import Nav from "../layouts/Nav";
import Footer from "../layouts/Footer";

const BrRino = () => {
  return (
    <div>
      <Nav />

      <section className="w-full overflow-hidden  wow fadeInUp">
        <div className="relative bg-cover bg-no-repeat ">
          <div className="pt-[150px] xl:pb-[200px]">
            <Container className="pt-5">
              <div className="flex flex-col gap-10  items-center justify-center">
                <img
                  src="../assets/img/brRino.jpg"
                  className="w-[50vh] rounded-full shadow-2xl"
                />
                <div className="flex flex-col items-center justify-center ">
                  <h3 className="text-3xl lg:text-5xl text-black leading-snug font-semibold ">
                    Br. Rino Jacob
                  </h3>
                  <h3 className="text-base text-gold-1 mt-4 text-justify lg:max-w-xl">
                    1982 - 2020
                  </h3>
                  <p className="text-base text-gold-1 mt-4 text-justify lg:max-w-xl">
                    Br.Rino was born on October 20, 1982 in
                    Thuravoor,Angamaly.He was the eldest son of Jacob and Mary.
                    Rino, had abdominal pain since 2004,so he went on to take
                    painkillers.
                    <br />
                    Later he was diagnosed with Crohn's disease and treated at
                    PVS Hospital, Ernakulam in 2008. Rino, who was taking
                    several pills a month, was brought to the Jesus Vision
                    Convention on Saturday, March 1, 2011 at the YMCA Hall in
                    Kuruppampadi, Perumbavoor. Through the servant of God's
                    counseling and healing ministry, Rino's pain was relieved
                    and later eliminated. In September 2011, by grace, he
                    attended a five - day Meditation on Reconciliation conducted
                    by Jesus Vision to understand what the true Christian life
                    is and became a disciple of Jesus Christ.
                    <br /> Despite of being a Catholic, Rino repented of not
                    living a Christ-centered life and turned away from
                    everything that the apostles did not teach. But he proceeded
                    by relying on the sacraments of the Church. <br />
                    He used to engage most of his time in social media by
                    debating with the word of Christ against the opposite
                    doctrine and would fight until the opposition understand the
                    truth.He also acted as an apologist against everything that
                    was not Christ centered.He was very careful in dealing with
                    Jesus Vision Youth in Angamaly by not letting them fall away
                    from spirituality and has been at the forefront of the main
                    audience for all of Jesus Vision meditations.
                    <br /> In January 2019, the symptoms of the disease started
                    again and he underwent an operation in Aluva
                    Rajagiri.Through everyone's prayers, he came back to life
                    and by knowing everything, Jisa became Rino's life partner.
                    Then Rino began to spread and preach the word of the Lord
                    with more power loudly on the main stages of Jesus Vision.
                    The Lord Jesus gave the message to his servant that the time
                    of Rino was near and the important servants in Jesus vision
                    started praying to Jesus Christ accordingly. That same week,
                    Rino was caught with chicken pox and joined the Lord on July
                    9, 2020 at 2:45 a.m. Rino has a daughter named ZIYONA.
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BrRino;
