import { useState, createRef, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { RiFacebookCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../components/buttons";
import { Checkbox, Input } from "../components/field";
import { Link, Loader } from "../components/utils";
import config from "../config";
import { toast } from "../helpers";
import AuthLayout from "../layouts/AuthLayout";
import { verifyOtpService, forgotPasswordService } from "../services/Authentication";
const email = config.AUTH.DRIVER.getItem("email");

const VerifyOtp = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const lineIndex = props.index;
  const numerOfInputs = props?.numerOfInputs || 5;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputRefsArray] = useState(() =>
    Array.from({ length: numerOfInputs }, () => createRef())
  );

  const [letters, setLetters] = useState(() =>
    Array.from({ length: numerOfInputs }, () => "")
  );

  const handleKeyPress = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < numerOfInputs - 1 ? prevIndex + 1 : 0;
      const nextInput = inputRefsArray?.[nextIndex]?.current;
      nextInput.focus();
      nextInput.select();
      return nextIndex;
    });
  };

  useEffect(() => {
    
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, 300000);
    if (inputRefsArray?.[0]?.current) {
      inputRefsArray?.[0]?.current?.focus();
    }

    window.addEventListener("keyup", handleKeyPress, false);
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, []);
  const sendOtp = () => {
    console.log(letters.length);

    if (letters.length === 5) {
      const fields = {
        code: letters.join(""),
      };
      let title = "";
      let success = "";
      verifyOtpService(fields).then((res) => {
        if (!res.isError) {
          title = "success";
          success = " Otp verified successfull";
          config.AUTH.DRIVER.setItem("token", res.data.token);
          navigate("/reset-password");
        } else {
          title = "error";
          success = res.message;
          setIsButtonDisabled(false)
        }
        setLetters(Array.from({ length: numerOfInputs }, () => ""));
        toast(title, success);
        setLoading(false);
      });
    }
  };
  const resentOtp = () => {
    if (email) {
      const fields = {
        email: email
      };
      let title = "";
      let success = "";
      console.log(email);
      forgotPasswordService(fields).then((res) => {
        if (!res.isError) {
          setLoading(false);
          title = "success";
          success = "Email Otp send successfull";
          config.AUTH.DRIVER.setItem("token", res.data.token);
        } else {
          title = "error";
          success = res.message;
        }
        toast(title, success);
        setLoading(false);
      });
    }

      
    }

  return (
    <AuthLayout
      title={
        <>
          Welcome back to <br /> our community
        </>
      }
    >
      <h3 className="text-center text-xl font-semibold text-gray-700">
        Email Verification
      </h3>
      <p className="text-center text-sm mt-2 mb-10">
        We have sent a code to your email
      </p>

      <div>
        <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs py-3">
          {inputRefsArray.map((ref, index) => {
            return (
              <div key={index} className="w-16 h-16 px-1">
                <input
                  className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-500 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                  type="text"
                  name=""
                  ref={ref}
                  id={`box${index}-1`}
                  onChange={(e) => {
                    const { value } = e.target;
                    setLetters((letters) =>
                      letters.map((letter, letterIndex) =>
                        letterIndex === index ? value : letter
                      )
                    );
                  }}
                  onClick={(e) => {
                    setCurrentIndex(index);
                    e.target.select();
                  }}
                  value={letters[index]}
                  max={"1"}
                />
              </div>
            );
          })}
        </div>
        <PrimaryButton onClick={sendOtp} disabled={loading}>
          {loading && <Loader color={"white"} />}
          <span>Send Verification</span>
        </PrimaryButton>
      </div>

      <div className="px-2">
        <button disabled={ isButtonDisabled } onClick={() => {
                    resentOtp();
                  }} >Resend OTP</button>
      </div>
    </AuthLayout>
  );
};

export default VerifyOtp;
