import { Link } from "react-router-dom";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "../components/utils";
import { useNavigate} from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <section className="w-full overflow-hidden bg-[#f2f3f9] wow fadeInUp">
      <div className="relative bg-cover bg-no-repeat ">
        <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
          <Container className="pt-5 flex flex-col md:flex-row md:items-center space-y-6 md:space-y-0 md:space-x-4 lg:space-x-0 lg:justify-between relative z-10">
            <Swiper
              className="justify-center"
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}>
              {/* <SwiperSlide className="relative ">
                <img
                  className=""
                  src="./assets/img/bible-bg.jpeg"
                  alt="slide 1"
                />
                <div className=" absolute bottom-0  flex flex-col  justify-center   top-0 mx-auto">
                  <h2 className="text-pelorous-900 px-8  lg:text-5xl leading-snug  text-xl  lg:w-1/2 w-full">
                    Our focus is to help you connect with Jesus through the
                    Bible and the Early Church teachings.
                  </h2>
                  <p className="text-pelorous-900 px-8 py-2 text-sm lg:w-1/2 w-full">
                    Now this is eternal life: that they know you, the only true
                    God, and Jesus Christ, whom you have sent. - John 17:3
                  </p>
                  <div className="px-8  py-2 w-1/2">
                    <button className="text-white bg-gold-100 text-sm rounded px-8  py-3 ">
                      {" "}
                      Get in Touch
                    </button>
                  </div>
                </div>
              </SwiperSlide> */}
               <SwiperSlide className="relative ">
                <img
                  className=""
                  src="https://res.cloudinary.com/dd0b5dye0/image/upload/v1707317875/bannerhome_tbyofe.jpg"
                  alt="slide 1"
                />
                <div className=" absolute bottom-0  flex flex-col  justify-end item-bottom   top-0 mx-auto">
           
                  <div className="px-8  py-2">
                    <button className="text-white bg-red-700  text-md rounded px-10 py-4  " onClick={()=>{
                       navigate('/prayer-request');
                    }}>
                     Send Prayer Requests
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Banner;
